import { withFetching } from 'bv-hocs';

import getQuickStakes from '../api/quick_stakes';
import QuickStakes from './quick_stakes';

const quickStakesBySport = (sportId) => (
  getQuickStakes().then((stakes) => (
    stakes.bySportId[sportId] || stakes.default
  ))
);

const QuickStakesContainer = withFetching(
  (props) => quickStakesBySport(props.sportId),
  'quickStakes',
  {
    showSpinner: false,
  },
)(QuickStakes);

export default QuickStakesContainer;
