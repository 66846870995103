import PropTypes from 'prop-types';

import { OnOff } from 'bv-components';

const FakeInput = ({
  edited, ew, ews, onCheckboxChange, value,
}) => (
  <div className="betslip-keypad-input-wrapper">
    <div className="betslip-keypad-input">
      <span className={edited ? 'edited' : ''}>{value}</span>
    </div>
    {ew && (
      <OnOff
        className="betslip-keypad-onoff"
        checked={ews}
        onChange={onCheckboxChange}
        inputId="betslip-keypad-onoff"
      />
    )}
  </div>
);

FakeInput.propTypes = {
  ew: PropTypes.bool.isRequired,
  ews: PropTypes.bool.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  edited: PropTypes.bool.isRequired,
};

export default FakeInput;
