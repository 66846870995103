import { Component } from 'react';
import PropTypes from 'prop-types';

import {
  chunk, isEqual, omit, range,
} from 'underscore';
import { t } from 'bv-i18n';
import { withTouch } from 'bv-hocs';

const Key = withTouch('betslip-keypad-active-key')('td');

class KeyBoard extends Component {
  constructor(props) {
    super(props);

    this.onAddChar = this.onAddChar.bind(this);
    this.removeChar = this.removeChar.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(omit(nextProps, 'value'), omit(this.props, 'value'));
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(event) {
    const { onEnterClick } = this.props;
    switch (event.key) {
      case 'Enter':
        onEnterClick();
        break;
      case 'Backspace':
        this.removeChar();
        break;
      default:
        this.addChar(event.key);
    }
  }

  onAddChar(event) {
    this.addChar(event.target.textContent);
  }

  addChar(char) {
    const { onChange, value } = this.props;
    const formattedValue = `${value}${char}`
      // Convert , into 0, and . into 0.
      .replace(/^([.|,])/, '0$1')
      // replace user inputted . or , to current locale's currency separator
      .replace(/[.,]/, t('javascript.currency.format.separator'));

    // digits + decimal point + 0 to 2 digits
    if (/^\d*([.|,]\d{0,2})?$/.test(formattedValue)) {
      onChange(formattedValue);
    }
  }

  removeChar() {
    const { onChange, value } = this.props;
    onChange(value.slice(0, -1));
  }

  render() {
    const { onEnterClick } = this.props;

    const rowsExtra = [[
      <Key onClick={this.removeChar}>
        <span className="betslip-keypad-back-icon" />
      </Key>,
    ], [
      <td className="betslip-keypad-enter" onClick={onEnterClick} rowSpan="3">
        <span className="betslip-keypad-enter-icon" />
      </td>,
    ]];

    const rows = [
      ...chunk(range(1, 10), 3).reverse(),
      ['00', 0, t('javascript.currency.format.separator')],
    ]
      .map((row) => (
        row.map((column) => <Key onClick={this.onAddChar}>{column}</Key>)
      ))
      .map((row, index) => row.concat(rowsExtra[index]));

    return (
      <div className="betslip-keypad-keys-wrapper">
        <table className="betslip-keypad-keys">
          {rows.map((row) => <tr>{row}</tr>)}
        </table>
      </div>
    );
  }
}

KeyBoard.propTypes = {
  onChange: PropTypes.func.isRequired,
  onEnterClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default KeyBoard;
