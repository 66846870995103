import { Component } from 'react';
import PropTypes from 'prop-types';

import { isEqual, omit } from 'underscore';
import { withTouch } from 'bv-hocs';

const QuickStake = withTouch('betslip-keypad__quick-stake--active')('li');

class QuickStakes extends Component {
  shouldComponentUpdate(nextProps) {
    // This is needed because the base value is received by the quick stakes
    // TODO: Think if this component should be agnostic to the value
    // And only calls a function that the parent component would execute to increment the value
    return !isEqual(omit(nextProps, 'value'), omit(this.props, 'value'));
  }

  addValue(increment) {
    return () => {
      const { onChange, value } = this.props;
      onChange(
        value.replace(
          /(^\d*)/,
          parseInt(value || 0, 10) + increment,
        ),
      );
    };
  }

  render() {
    const { quickStakes } = this.props;

    if (!quickStakes || quickStakes.length === 0) return null;

    return (
      <ul className="betslip-keypad__quick-stakes">
        {quickStakes.map((value) => (
          <QuickStake key={value} className="bvs-link" onClick={this.addValue(value)}>
            <span>+</span>
            <span>{value}</span>
          </QuickStake>
        ))}
      </ul>
    );
  }
}

QuickStakes.propTypes = {
  onChange: PropTypes.func.isRequired,
  quickStakes: PropTypes.instanceOf(Array).isRequired,
  value: PropTypes.string.isRequired,
};

export default QuickStakes;
