import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const Bar = ({ onBackClick }) => (
  <div className="betslip-keypad-bar">
    <span className="bvs-icon is-arrow-left bvs-link" onClick={onBackClick} />
    {t('javascript.betslip_keypad.bar_title')}
  </div>
);

Bar.propTypes = {
  onBackClick: PropTypes.func.isRequired,
};

export default Bar;
