import { getJSON } from 'bv-fetch';
import { accountNumber } from 'bv-helpers/session';

const quickStakesMemo = {};

export default () => {
  if (quickStakesMemo[accountNumber()]) {
    return quickStakesMemo[accountNumber()];
  }

  quickStakesMemo[accountNumber()] = getJSON('/api/quick_stakes', { l: accountNumber() })
    .catch(() => ({
      bySportId: [],
      default: [],
    }));

  return quickStakesMemo[accountNumber()];
};
