import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Bar from './components/bar';
import BetInfo from './components/bet_info';
import FakeInput from './components/fake_input';
import Keyboard from './components/keyboard';
import QuickStakesContainer from './components/quick_stakes_container';
import getQuickStakes from './api/quick_stakes';

// Prefetch quick stakes
getQuickStakes();

class App extends PureComponent {
  constructor(props) {
    super(props);

    this.onAccept = this.onAccept.bind(this);
    this.toggleEWS = this.toggleEWS.bind(this);
    this.updateValue = this.updateValue.bind(this);

    this.state = {
      ews: props.ews,
      value: props.value,
      edited: false,
    };
  }

  onAccept() {
    const { value, ews } = this.state;
    const { onAccept } = this.props;
    onAccept(value, ews);
  }

  toggleEWS() {
    const { ews } = this.state;
    this.setState({
      ews: !ews,
    });
  }

  updateValue(value) {
    this.setState({
      edited: true,
      value,
    });
  }

  render() {
    const { edited, value } = this.state;
    const { bet, onBackClick } = this.props;

    return (
      <div className="betslip-keypad-wrapper">
        <div className="betslip-keypad">
          <Bar onBackClick={onBackClick} />

          <div className="row">
            <div className="column">
              <BetInfo {...bet} />
              <FakeInput
                ew={bet.ew}
                onCheckboxChange={this.toggleEWS}
                {...this.state}
              />
            </div>
            <div className="column">
              <QuickStakesContainer
                onChange={this.updateValue}
                sportId={bet.sport_id}
                value={value}
              />
              <Keyboard
                onEnterClick={this.onAccept}
                onChange={this.updateValue}
                value={edited ? value : ''}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

App.propTypes = {
  bet: PropTypes.instanceOf(Object).isRequired,
  ews: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default App;
