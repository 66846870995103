/* eslint react/prefer-stateless-function: off */
import { createElement, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const BetInfo = ({ children, title }) => (
  <div className="betslip-keypad-bet-info">
    <div className="betslip-keypad-title">{title}</div>
    {children}
  </div>
);

BetInfo.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

BetInfo.defaultProps = {
  children: undefined,
};

const SingleBetInfo = ({
  event_description: eventDescription,
  market_description: marketDescription,
  outcome_description: outcomeDescription,
  period_description: periodDescription,
  price_text_formatted: priceTextFormatted,
}) => (
  <BetInfo title={`${outcomeDescription} - ${priceTextFormatted}`}>
    {eventDescription}
    <br />
    {`(${marketDescription}, ${periodDescription})`}
  </BetInfo>
);

SingleBetInfo.propTypes = {
  outcome_description: PropTypes.string.isRequired,
  price_text_formatted: PropTypes.string.isRequired,
  event_description: PropTypes.string.isRequired,
  market_description: PropTypes.string.isRequired,
  period_description: PropTypes.string.isRequired,
};

const MultipleBetInfo = ({ description, multiplicity }) => (
  <BetInfo title={`${description} - ${multiplicity} ${t(multiplicity > 1 ? 'bets' : 'bet')}`} />
);

MultipleBetInfo.propTypes = {
  description: PropTypes.string.isRequired,
  multiplicity: PropTypes.number.isRequired,
};

class BetInfoContainer extends PureComponent {
  render() {
    const { win_bet_type_id: winBetTypeId } = this.props;
    return createElement(
      winBetTypeId > 1 ? MultipleBetInfo : SingleBetInfo,
      this.props,
    );
  }
}

BetInfoContainer.propTypes = {
  win_bet_type_id: PropTypes.number.isRequired,
};

export default BetInfoContainer;
